import PropTypes from "prop-types";
import { Title, ContentContainer } from "./style";
import React from "react";

const Section = React.forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      <div>
        { props.title && <Title>{props.title}</Title> }
      </div>
      <ContentContainer
        padding={props.padding}
        isGray={props.isGray}
        rowGap={props.rowGap}
      >
        {props.children}
      </ContentContainer>
    </div>
  );
});

export default Section;

Section.propTypes = {
  title: PropTypes.string,
  isGray: PropTypes.bool,
  rowGap: PropTypes.string,
  padding: PropTypes.string
};
