import styled from "styled-components";

export const NewsContainer = styled.div`
    margin: 5rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;

export const NewsTitle = styled.h3`
    color: ${ ({ theme }) => theme.colors.primary };
`;

export const NewsImage = styled.img`
    width: 80%;
`;

export const NewsBody = styled.div`
    margin: 2rem 1rem;
    width: 100%;
    flex: 1;
    overflow-x: auto;
    overflow-y: hidden;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
`;