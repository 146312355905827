import { SUCCESS_LOGGIN, LOADED_USER_INFO } from "../storeConstants"

const initialState = {
    isAutenticated: false,
    //fake data
    /*name: 'BARRERA RUIZ JUAN CARLOS',
    surname: '',
    division: 'Autobuses Estrella Blanca',
    email: 'cbarrera@estrellablanca.com.mx',
    position: 'Lider Canales Digitales',
    phone: '5580126698',    
    imss: '45987621914',
    image: '/images/persons/JCBRpic.jpg'*/
    // to load real data
    name: '',
    surname: '',
    division: '',
    email: '',
    position: '',
    phone: '',    
    imss: '',
    image: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
   case SUCCESS_LOGGIN:
       return {
           ...state,
           isAutenticated: true,
           ...action.data
       }
    case LOADED_USER_INFO:
      return{
        ...state,
        ...action.data
      }
    default:
      return state
  }
}
