import React from 'react';
import { 
    MainHolder, 
    ContentHolder,
    SideBarHolder, 
    NavHolder,
    NavLogo,
    NavRigthHolder,
    NavImgItem,
    UserNameHolder,
    UserPicHolder
} from './style';
import { SideBar } from '..';
import { Link, navigate } from 'gatsby';
import { useSelector, connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { loadUserInfo } from '../../store/actions/user.action';

const MainContainer = ({ children, padding, loadUserInfo }) => {
    const { user } = useSelector(state=>state) 
    loadUserInfo()
    return(
        <>
            <NavHolder >                            
                <NavLogo src={"/images/logos/logo_estrella_flat_green.svg"} onClick={()=>navigate('/main',{state:{animation:false}})} />                
                <NavRigthHolder className="responsive">
                    <NavImgItem src={"/images/icons/ico_mensaje_1.svg"}/>
                    <NavImgItem src={"/images/icons/ico_alarma_2.svg"}/>
                    <Link to={"/profile"} style={{display:"flex", flexDirection: "row", alignItems: "center", justifyContent: "center", cursor: "pointer"}}>
                        <UserNameHolder>
                            {user.name}
                        </UserNameHolder>
                        <UserPicHolder src={ user.image ? user.image : "/images/icons/ico_user_login.svg"} />
                    </Link>
                    <NavImgItem height={"35%"} src={"/images/icons/ico_configuracion.svg"}/>
                </NavRigthHolder>
            </NavHolder>
            <MainHolder>
                <SideBarHolder>
                    <SideBar />
                </SideBarHolder>
                <ContentHolder padding={padding}>
                    {children}
                </ContentHolder>            
            </MainHolder>
        </>
    )
}
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        loadUserInfo: loadUserInfo
    }, dispatch)
  }
  

export default connect(null,matchDispatchToProps)(MainContainer)