export const theme = {
  colors: {
    black: "#000000",
    white: "#ffffff",
    gray: "#dddddd",
    labels: "#0b0b0b",
    primary: "#219653",
    mediumPrimary: "#27AE60",
    ligthPrimary: "#6fcf97",
    secondary: "#828282",
    strongSecondary: "#444444",
    mediumSecondary: "#BDBDBD",
    ligthSecondary: "#F2F2F2"
  },
  fonts: ["Eras"],
  sizes: {
    labels: "2rem",
    switch: {
      height: 20,
      width: 40
    }
  }
};
