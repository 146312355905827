import React from 'react'
import {
    ImageHolder,
    UserImg,
    UserEditContainer,
    UserEditImg,
    InfoHolder,
    InfoTitle,
    InfoText
} from './style'
import { 
    Section,
    Row 
} from '../index'
import { useSelector} from 'react-redux';

export default () => {
    const { user } = useSelector(state=>state) 
    return (        
        <React.Fragment>
            <Section isGray padding={"0"}>
                <Row width="auto" responsive padding={"1.5rem 1rem"}>
                    <ImageHolder>
                        <UserImg src={user.image ? user.image : "/images/icons/ico_user_login.svg" } />    
                        <UserEditContainer>
                            <UserEditImg src={"/images/icons/ico_camara.svg"} />    
                        </UserEditContainer>
                    </ImageHolder>                      
                    <InfoHolder>
                        <Row responsive padding={"0"}>
                            <InfoTitle>Nombre: </InfoTitle>
                            <InfoText>{user.name + ' ' + user.surname}</InfoText>
                        </Row>
                        <Row responsive padding={"0"}>
                            <InfoTitle>Empresa: </InfoTitle>
                            <InfoText>{user.division}</InfoText>
                        </Row>
                        <Row responsive padding={"0"}>
                            <InfoTitle>Puesto: </InfoTitle>
                            <InfoText>{user.position}</InfoText>
                        </Row>
                    </InfoHolder>
                    <InfoHolder>
                        <Row responsive padding={"0"}>
                            <InfoTitle>Telefono: </InfoTitle>
                            <InfoText>{user.phone}</InfoText>
                        </Row>
                        <Row responsive padding={"0"}>
                            <InfoTitle>Correo: </InfoTitle>
                            <InfoText>{user.email}</InfoText>
                        </Row>
                        <Row responsive padding={"0"}>
                            <InfoTitle>NSS: </InfoTitle>
                            <InfoText>{user.imss}</InfoText>
                        </Row>
                    </InfoHolder>
                </Row>
            </Section>
            <Section>                
            </Section>
        </React.Fragment>
    )
}