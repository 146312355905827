import styled from "styled-components";

export const Title = styled.h1`
  font-size: 3rem;
  color: ${props => props.theme.colors.mediumPrimary};
  margin: 0;
  margin-bottom: 2rem;
  text-transform: uppercase;
`;

export const ContentContainer = styled.div`
  background: ${props =>
    props.isGray ? props.theme.colors.gray : "transparent"};
  display: grid;
  grid-row-gap: ${props => (props.rowGap ? props.rowGap : "")};
  padding: ${props => (props.padding ? props.padding : "1rem 20%")};
`;
