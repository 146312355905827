import { SUCCESS_LOGGIN, LOADED_USER_INFO } from "../storeConstants"
import api from '../../services/api'
export function makeLogin( userData ) {
    return function(dispatch) {
        dispatch({type: SUCCESS_LOGGIN, data: userData})
    }
}


export function loadUserInfo( userData ) {
    return function(dispatch, getState) {        
        const state = getState()
        if(state.user.name === ''){
            api.get('/users/profile',{
                headers: { "Authorization": "Bearer " + state.user.access_token }
            }).then(res=>{                        
                if (res.status === 200) {
                    if (res.data.errors) {
                        console.log('algo fallo al cargar informacion de usuario',res.data)                    
                    } else {                    
                        dispatch({type: LOADED_USER_INFO, data: res.data.data})
    
                    }
                }             
            })   
        }                     
    }
}
