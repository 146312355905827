import React, { useState } from 'react'
import { 
    SignInHolder, 
    FormImg, 
    FormContainer, 
    Form,
    ImageSignIn,
    LoginLegend,
    ForgotPasswordLegend
} from './style'
import { navigate } from 'gatsby';
import {
    Button, 
    Input
} from '../index'
import { theme } from '../../theme';
import Loader from 'react-loader-spinner'
import api from '../../services/api'

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { makeLogin } from '../../store/actions/user.action';


const SignIn = ({makeLogin}) => {
    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState({
        name: '',
        password: '',
        general: ''
    })
    const validateData = async (e) => {
        e.stopPropagation();
        setLoading(true);     
        const data = {
            geb_employee_number: String(document.forms["singin"].name.value).trim(),
            password: String(document.forms["singin"].password.value).trim(),
        }           
        if (!data.geb_employee_number || !data.password) {
            let e = {
                name: data.geb_employee_number ? "" : "Este campo es requerido" ,
                password: data.password ? "" : "Este campo es requerido" ,
            } 
            setLoading(false);
            setError(e)
        } else {
            // call for login
            try{
                const response = await api.post('/login',data)
                console.log(response)
                if (response.status === 200) {
                    if (response.data !== "Bad Request") {
                        if (response.data.access_token) {   
                                                     
                            makeLogin({...response.data, createdAt: new Date()})
                            navigate('/main')
                        } else {
                            if (response.data === 'Not Found') {
                                let e = error
                                e.general = "Usuario no encontrado"
                                setError(e)
                                setLoading(false)
                            } 
                            if(response.data.errors){
                                let e = error
                                e.general = "Usuario o contraseña erroneos"
                                if (response.data.errors.message === "unauthorized") {
                                    e.general = "Contraseña invalida"                                                           
                                }
                                setError(e) 
                                setLoading(false)
                            }
                            else {
                                let e = error
                                e.general = "Usuario o contraseña erroneos"
                                setError(e)
                                setLoading(false)
                            }
                            
                        }
                    } else {
                        let e = error
                        e.general = "Hubo un error inesperado al realizar la peticion"
                        setError(e)
                        setLoading(false)
                    }
                }
            } catch(e){
                console.log(e)                
                e.general = e.toString()
                e.name=''
                e.password=''
                setError(e)
                setLoading(false)
            }                     
        }             
    }
    return(
        <SignInHolder>
            <ImageSignIn url={"/images/futura.jpg"}/>
            <FormContainer>
                <LoginLegend>
                    Bienvenido a la red interna de
                </LoginLegend>
                <FormImg src="/images/logos/logo_estrella_flat_green.svg" alt="Logo Estrella" />                
                <Form id="singin" >
                    <Input 
                        border={"1px solid " + theme.colors.primary} 
                        name="name" 
                        className={error.name?'error':''} 
                        placeholderColor={theme.colors.secondary} 
                        placeholder={"Número de empleado"} 
                        flex={"0"}
                        margin={"1rem 0 0 0"}
                        width={"75%"}
                    />
                    <p className="errorHolder">{error.name}&nbsp;</p>
                    <Input 
                        border={"1px solid " + theme.colors.primary} 
                        type="password" 
                        name="password" 
                        className={error.password?'error':''} 
                        placeholderColor={theme.colors.secondary} 
                        placeholder={"Contraseña (RFC)"} 
                        flex={"0"}
                        margin={"1rem 0 0 0"}
                        width={"75%"}
                    />
                    <p className="errorHolder">{error.password}&nbsp;</p>
                </Form>
                { error.general && <p className="errorHolder">{error.general}&nbsp;</p>}
                <ForgotPasswordLegend>
                    ¿Olvidaste tu contraseña? Envíanos un correo a <a href="mailto:miestrellablanca@estrellablanca.com.mx">miestrellablanca@estrellablanca.com.mx</a>
                </ForgotPasswordLegend>
                { loading ? 
                    <Loader type="Grid" color={theme.colors.primary} height={100} width={100}/>:
                    <Button text={"Acceder"} onClick={validateData} width={"75%"} />
                }
            </FormContainer>
        </SignInHolder>
    )
}
function  mapStateToProps(state){
    return state;
  }
  function matchDispatchToProps(dispatch){
    return bindActionCreators({
        makeLogin: makeLogin
    }, dispatch)
  }
  
export default connect(mapStateToProps,matchDispatchToProps)(SignIn);