import React from 'react'
import { Link } from "gatsby"
import { NavItemHolder, NavItemText } from './style';
import  { Location } from '@reach/router';

const NavItem = props => {
    if (props.button) {
        return(            
        <NavItemHolder {...props} onClick={props.onclick}>
            <NavItemText button>{props.text}</NavItemText>
        </NavItemHolder>) 
    }
    /*if (props.dropdown) {
        return(            
        <NavItemHolder {...props} onClick={props.onclick}>
            <NavItemText>{props.text}</NavItemText>
        </NavItemHolder>) 
    }*/
    return(
    <Link to={props.route}>
        <Location>
            {({ location }) => {
                const type = location.pathname === props.route ? 'active' : '';                
                return <NavItemHolder {...props}>
                    <NavItemText className={ type }>{props.text}</NavItemText>
                </NavItemHolder>  
            }}
        </Location>                      
    </Link>)
}

export default NavItem