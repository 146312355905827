import styled from "styled-components";

export const ModalHolder = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;    
    z-index: 10001;
    color: white;
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;    
    flex-direction: column;   
`;

export const CloseIcon = styled.img`
    position: absolute;
    top: 1rem;
    right: 1rem;
    height: 1.5rem;
    cursor: pointer;
`;