import styled from "styled-components"

export const ImageHolder = styled.div`
    flex: 1;
    position: relative;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const InfoHolder = styled.div`
    flex: 2;
    display: flex;
    flex-direction: column;    
    align-items: flex-start;
    justify-content: flex-start;
`;

export const InfoTitle = styled.p`
    flex: 1;
    padding: .5rem .5rem;
    color: ${ ({ theme }) => theme.colors.primary };
    font-family: Eras;
    text-align: right;
    font-weight: bolder;
    font-size: 1.05rem;
    margin: 0;
`;

export const InfoText = styled.p`
    flex: 2;
    padding: .5rem .1rem;
    font-family: Eras;
    text-align: left;
    margin: 0;
`;

export const UserImg = styled.img`
    height: 8rem;
    width: 8rem;
    border: 1px solid ${ ({ theme }) => theme.colors.primary };
    border-radius: 8rem;
    padding: .2rem;
    object-fit: cover;
`;

export const UserEditContainer = styled.div`
    height: 1.5rem;
    width: 1.5rem;    
    border-radius: 2rem;    
    object-fit: contain;
    position: absolute;
    right: 1rem;
    bottom: 0;
    background-color: white;
    border: 1px solid ${ ({ theme }) => theme.colors.secondary };
    padding: .2rem;
`;
export const UserEditImg = styled.img`
    height: 100%;
    width: 100%;
`;
