import styled from "styled-components";
import { breakpoints } from "../../breakpoints";

export const SNav = styled.nav`
    padding: 1rem 0px 1rem 0;
    outline: none;
    display: flex;
    flex-direction: row;
    background: ${ ({ theme, backgroundColor }) => backgroundColor ? backgroundColor : theme.colors.primary };    
    min-height: 2.5rem;
    width: 100%;
    position: fixed;
    top: 1rem;
    z-index: 10000;
    transition: all 350ms;
    &.scroll{
        top: 0;
        background-color: ${ ({ theme }) => theme.colors.strongSecondary };    
    }
`;

export const SNavLogo = styled.img`    
    height: 100%;
    padding-left: 10rem;
`;

export const SNavLogoHolder = styled.div`    
    display: flex;
    position: relative;
    flex-direction: row;
    height: 2.5rem;    
    padding: 0 0.3125rem;
`;

export const SLinkLogoHolder = styled.a`        
    &:visited:link:active {                
        text-decoration: none;
    }    
    cursor: pointer;
`;

export const SBrandName = styled.p`        
   margin: auto;
   min-height: 100%;
   color: ${ ({ theme, textColor }) => textColor ? textColor : theme.colors.white };
   line-height:2.5rem;
   padding: 0 1rem;
`;

export const NavMenu = styled.div`
    display: flex; 
    flex: 1;
    position: absolute;    
    z-index: 99;
    @media (min-width: 0px) {
        padding-top: 2.5rem;          
        flex-direction: column;  
        width: 100%;
        div:last-child{
            border-bottom-right-radius: 0.3125rem;         
            border-bottom-left-radius: 0.3125rem;         
            padding-bottom: ${({show})=>show?"20px":""};
        }
    }
    @media ${breakpoints.laptop} {
        .icon{
            display:none;
        }
        .responsive{
            display: flex !important;
            width: auto !important;            
        }
        padding-top: 0;  
        flex-direction: row;
    }
`;

export const NavItemsHolder = styled.div`    
    flex: 1;    
    justify-content: ${ ({ rigth }) => rigth ? 'flex-end' : 'flex-start' };
    align-content: center;    
    display: flex;
    padding: 0 20px;
    @media (min-width: 0px) {
        .responsive:not(.show){
            display:none;
        }
        .responsive.show{            
            width:100%;
            background:${ ({ theme, backgroundColor }) => backgroundColor ? backgroundColor : theme.colors.primary };                        
        }                
        display:flex; 
        flex-direction: column;  
        background:${ ({ theme, backgroundColor }) => backgroundColor ? backgroundColor : theme.colors.primary };
    }
    @media ${breakpoints.laptop} {
        .icon{
            display:none;
        }
        .responsive{
            display: flex !important;
            width: auto !important;            
        }
        display: flex;
        flex-direction: row;
        background-color: transparent;
    }
`;

export const MenuIcon = styled.div`    
    font-size: 32px;
    color: transparent;
    user-select: none;
    position: absolute;
    z-index: 999;
    top:0;
    right: 10px;
    display: inline-block;
    box-sizing: border-box;
    overflow: visible;
    vertical-align: top;
    font-style: normal;
    height: 1em;
    width: 1em;
    mix-blend-mode: multiply;
    cursor: pointer;
    &:before,:after{
        border-color: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.white};
        content: '';
        position: absolute;
        display: block;
        box-sizing: border-box;
    }
    &:before{ 
        border-color: ${({ theme }) => theme.colors.white};
        height: 0.75em;
        width: 1em;
        border-width: 0.125em 0;
        background: transparent;
        border-style: solid;
        top: 0.125em;
        left: 0;
        border-radius:2px;
    }
    &:after{ 
        border-color: ${({ theme }) => theme.colors.white};
        height: 0px;
        width: 1em;
        background: transparent;
        border-width: 0.125em 0 0 0;
        border-style: solid;
        top: 0.4375em;
        left: 0;
        border-radius:2px;
    }
    &:hover{
        border-color: #777;
    }
`;

export const SLinkItemHolder = styled.a`        
    &:visited:link:active {                
        text-decoration: none;
    }
    cursor: pointer;
`;

export const NavItemHolder = styled.div`    
    display: flex;
    position: relative;
    flex-direction: row;
    min-height: 2.5rem;    
    padding:   ${({ button })=>button?'0 1rem':' 0 0.3125rem '};;
    background-color: ${({ theme, button })=>button?theme.colors.primary:''} !important;
    border-radius: ${({ button })=>button?'0.3125rem':'10px !important'};
    cursor: ${({  button })=>button?'pointer':''};

    @media (min-width: 0px) {
        &:hover{
            background-color: ${({  hoverColor, theme })=>hoverColor?hoverColor:theme.colors.mediumPrimary} !important;
            color: #ffffff;
        }
    }
    @media ${breakpoints.laptop} {
        &:hover{
            background-color: ${({  button })=> button ? 'default' : 'transparent' } !important;            
        }        
    }
    
`;

export const NavItemText = styled.p`    
    margin: auto;
    height: 100%;
    color: ${({  button })=> button ? '#ffffff' : '#b0b0b0' };
    line-height:2.5rem;
    padding: 0 1rem;    
    white-space: nowrap;
    font-size: .725rem;
    &:hover{        
        color: ${({  button })=> button ? '' : '#fff' };
    }  
    @media (min-width: 0px) {        
        color: #ffffff;
    }
    @media ${breakpoints.laptop} {
        color: ${({  button })=> button ? '#ffffff' : '#b0b0b0' };      
    }
    &.active{
        color: #ffffff;
    }
`;