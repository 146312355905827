import React from 'react'
import { 
    NewsContainer, 
    NewsTitle,
    NewsImage,
    NewsBody
} from './style'
import ReactHtmlParser from 'react-html-parser';

export default ({news}) => { 
    return(
        <NewsContainer>
            <NewsTitle>{news.fullTitle}</NewsTitle>
            { news.showImg && <NewsImage src={news.img} /> }
            <NewsBody aling="center">
                &nbsp;
                {ReactHtmlParser(news.body)}
            </NewsBody>
        </NewsContainer>
    )
}