import styled from "styled-components";

export const SInput = styled.input`
    padding: .8rem 2rem;
    background-color: #fff;
    &::placeholder{
        color: ${({ placeholderColor })=> placeholderColor ? placeholderColor : '#deebe4'};;
    }
     &:focus{
        color: #19593b;
    }
    border-radius: .5rem;
    box-shadow: none;
    border: ${({ border })=> border ? border : 'none'};
    flex: ${({flex})=>flex?flex:'1'};
    margin: ${({margin})=>margin?margin:'1rem'};
    width: ${({ width })=> width ? width : '80%'};
    text-align: center;
`;