import React, { useState, useEffect } from 'react'
import { SNav, SNavLogo, SNavLogoHolder, SBrandName, NavMenu } from './style'
import PropTypes from "prop-types";
import NavItems from './NavItems'
import { Link } from "gatsby";

const Nav = props => {
  const [show, setShow] = useState(false);  

  const [ scrolled, setScrolled ] = useState(false);  
  const handleScroll = (event) => {
    let scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;    
    if ( scrollTop > 200) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }  
    if( props.scrolled === true ){
      setScrolled(true)
    }
  }

  useEffect( () => {
    window.addEventListener('scroll', handleScroll);
    return () => {      
      window.removeEventListener('scroll', handleScroll);
    }
  });

  return(
    <SNav {...props.navProps} className = {( scrolled ? 'scroll' : '' )}>
      { (props.logo || props.brandName) && 
        <Link to="/">
            <SNavLogoHolder>
              {props.logo && <SNavLogo src={props.logo} alt={props.brandName} /> }
              {props.brandName &&<SBrandName>{props.brandName}</SBrandName> }
            </SNavLogoHolder>       
        </Link> 
      }
      <NavMenu show={show}>      
        <NavItems items={props.leftItems} show={show} setShow={setShow} />        
        <NavItems items={props.rigthItems} show={show} setShow={setShow} rigth/>          
      </NavMenu>
    </SNav>  
  )
}

export default Nav

Nav.defaultProps = {
  leftItems: [],
  logo: "",
  brandName: "",
  rigthItems: [], 
  navProps: {}
};

Nav.propTypes = {
  leftItems: PropTypes.array,
  logo: PropTypes.string,
  brandName: PropTypes.string,
  rigthItems: PropTypes.array,
  navProps: PropTypes.object  
};