import styled from "styled-components";
import { breakpoints } from "../../breakpoints";

export const NewsContainer = styled.div`
    margin: 1rem .5rem ;
    padding: 1rem 1rem;
    box-shadow: 0 0 .2rem rgba( 0, 0, 0, 0.2 );
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    transition: all 250ms;
    transform: scale(1);
    &:hover{
        transform: scale(1.05);
        margin: 1rem  1rem ;
        box-shadow: 0 0 .8rem rgba( 0, 0, 0, 0.2 );
    }
`;

export const NewsTitle = styled.h3`
    color: ${ ({ theme }) => theme.colors.primary };
    margin:0;   
    font-family: Eras; 
    flex:3;
`;

export const NewsSubTitle = styled.h5`
    color: ${ ({ theme }) => theme.colors.secondary };
    font-style: italic;
    margin: 0.3rem 0;    
    font-family: Eras;
    font-weight: bolder;
    flex:1;
    text-align: right;
`;

export const NewsBody = styled.p`        
    margin: 0.3rem 0;    
    font-family: Eras;    
    text-align: justify;
`;

export const NewsImg = styled.img`            
    padding: 1rem 0;
    @media (min-width: 0px) {              
        width: 80%;
    }        
    @media ${breakpoints.laptop} {
        height: 10rem;
        width: auto;
    } 
`;