import React, { useEffect } from 'react'
import { navigate } from "gatsby";
import {connect} from 'react-redux';

const UrlValidator =  ({user, location}) => {    
    useEffect(()=>{
      if (location.pathname !== '/') {
        if (!user.isAutenticated) {
          navigate("/")
        }
      }
    })
    
    return (<div style={{display:'none'}}>&nbsp;</div>)
}


function  mapStateToProps(state){
    return state;
}  
  
export default connect(mapStateToProps,null)(UrlValidator);
