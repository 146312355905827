import styled from "styled-components";
import { breakpoints } from "../../breakpoints";

export const SideBarHolder = styled.div`
    display: block;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;
    background-image: url('/images/backgrounds/fondo_menu.jpg');
    background-size: 100% 100%;                
    @media (min-width: 0px) {              
        padding: 0;
    }    
    @media ${breakpoints.laptop} {
        padding: 0 1rem;
    }   
`;

export const SideBarSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: ${ ({ flex }) => flex ? flex : '1'}; 
    justify-content: ${ ({ justifyContent }) => justifyContent ? justifyContent : "center" };
    padding: 1rem 0;    
    .activeLink{
        background-color: #00A467;
        margin: 0 -1rem;                
    }
`;

export const SideBarUserImageHolder = styled.div`
    margin: 1rem auto;
    flex: 1rem;
`;

export const SideBarUserImage = styled.img`
    height: 8rem;
    width: 8rem;
    border: 1px solid ${ ({ theme }) => theme.colors.white };
    border-radius: 100%;
    padding: .2rem;
    object-fit: cover;
    @media (min-width: 0px) {
        height: 4rem;
        width: 4rem;
    }    
    @media ${breakpoints.laptop} {
        height: 8rem;
        width: 8rem;
    }
`;

export const SideBarRow = styled.div`
    display: flex;
    flex-direction: row;
    height: 2rem;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    cursor: pointer;
    margin: .3rem 0 ;    
    
    @media (min-width: 0px) {
        .responsive{
            display: none;
        }
    }    
    @media ${breakpoints.laptop} {
        .responsive{
            display: flex;
        }
    }
`;

export const SideBarItemText = styled.p`
    color: white;
    font-family: Eras;
    font-size: 1.1rem;
    letter-spacing: 2px;
    flex: 5;    
`;

export const SideBarButton = styled.button`
    width: 100%;
    margin: 1rem 0;
    padding: 0.4rem;
    background-color: transparent;
    color: white;
    border-radius: .4rem;     
    font-family: Eras ;
    cursor: pointer;
    box-shadow: none;
    border: 1px solid white;
    @media (min-width: 0px) {
        font-size:.7rem
    }    
    @media ${breakpoints.laptop} {
        font-size: 1.1rem;
    }
`;

export const SideBarIcon = styled.img`    
    height: ${ ({ height }) => height ? height : '50%'};  
    margin: 0 1rem ;
    cursor: pointer;
    flex: ${ ({ flex }) => flex ? flex : '1'}; 
`;