import styled from "styled-components";
import { breakpoints } from "../../breakpoints";

export const SignInHolder = styled.div`
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
`;

export const ImageSignIn = styled.div`
    flex: 3;
    height: 100%;    
    justify-content: center;
    align-items: center;    
    position: relative;
    background-image: url(${ ({url}) => url});
    background-position: center;    
    background-size: cover;
    background-repeat: no-repeat;
    @media (min-width: 0px) {        
        display: none;    
    }    
    @media ${breakpoints.tablet} {        
        display: flex;      
    }
`;

export const ImageBlurSignIn = styled.div`    
    background-color: rgba(196,196,195,.45);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
`;

export const FormContainer = styled.div`
    flex: 1;
    height: 100%;  
    padding: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .errorHolder{
        margin: 0;
        padding: 0;
        flex: 0;
        color: red;
        font-size: 0.8rem;
        font-style: italic;
        padding-bottom: 1rem;
    }
`;

export const Form = styled.form`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const FormImg = styled.img`    
    @media (min-width: 0px) {        
        width: 90%;
    }    
    @media ${breakpoints.tablet} {        
        width: 100%;
    }
    @media ${breakpoints.laptopL} {        
        width: 95%;
    }
`;

export const LoginLegend = styled.span`
    font-size: 1.2rem;
    letter-spacing: 2px;
`;

export const ForgotPasswordLegend = styled.span`
    font-size: .75rem;
    letter-spacing: 1px;
    color: ${ ({ theme }) => theme.colors.secondary };
    margin: 1.5rem 1rem;
    a{
        color: ${ ({ theme }) => theme.colors.primary };
        font-style: italic;
        font-weight: bold;
    }
`;