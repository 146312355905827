import React from 'react'
import { RowContainer } from "./style"

const Row = props => {
    return(
        <RowContainer {...props}>
            { props.children }
        </RowContainer>
    )
}

export default Row;