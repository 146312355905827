import React, { useEffect } from "react"
import { ThemeProvider } from "styled-components"
import { theme } from "../../theme"
import { createGlobalStyle } from 'styled-components';
import { mainStore } from '../../store/store';
import { Provider } from "react-redux";
import UrlValidator from "../../services/UrlValidator";

const GlobalStyle = createGlobalStyle`  
 
  body {
    margin: 0;
    padding: 0;
    font-family: ${theme.fonts};    
  }

  a{
    text-decoration: none;
  }

  /* width */
::-webkit-scrollbar {
  width: .35rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: ${theme.colors.primary};
  border-radius: 0.5rem;  
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: ${theme.colors.mediumPrimary};
}

  @font-face {
    font-family: Eras;
    src: url('/styles/fonts/ERASMD.TTF');
  }
  @font-face {
    font-family: Eras Ligth;
    src: url('/styles/fonts/ERASLGHT.TFF');
  }
  @font-face {
    font-family: Eras Bold;
    src: url('/styles/fonts/erasbolditc.ttf');
  }

  @font-face {
    font-family: Eras ITC;
    src: url('/styles/fonts/eras-ultra-itc.ttf');
  }
`;
 
export default ({ children,title, location }) => {
  useEffect(()=>{
    document.title = title ? title : 'Grupo Estrella Blanca' 
  })

  return(
    <React.Fragment>  
       <Provider store={mainStore}>
         <UrlValidator location={location} />
          <ThemeProvider theme={theme} >  
            <GlobalStyle />       
              {children}        
          </ThemeProvider>
        </Provider>      
    </React.Fragment> 
  )
}
