import React from 'react';
import { 
    SlidesHolder, 
    SlidesButton, 
    SlideHolder, 
    SlideBlur, 
    SlideContainer, 
    SlideTitle, 
    SlideBody,
    SlideDotContainer,
    SlideDot,
    SlideImage  
} from "./style"
import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

function useInterval(callback, delay) {
    const savedCallback = useRef();
  
    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

const Slides = props => {

    const [ index, setIndex ] = useState( 0 )    
    const changeSlide = () => {
        if (props.autoMove) {
            setIndex(index === props.slides.length - 1 ? 0 : index + 1)
        }
    }
    
    const timeSlide = props.timeSlide ? props.timeSlide : 5000;
    useInterval(changeSlide, timeSlide);
        

    return (
        <SlidesHolder {...props}>                        
            { props.slides.map((slide,slideIndex) =>
                <SlideHolder key={"slide"+slideIndex} {...slide} {...props.slides} className = {slideIndex === index ? 'activeSlide': ''}>
                    <SlideBlur {...slide} {...props.slides} onClick={()=>{}}>
                        <SlideContainer {...slide} {...props.slides} onClick={()=>{}}>
                            <SlideTitle>{slide.title}</SlideTitle>
                            <SlideBody>{slide.body}</SlideBody>
                            { slide.extraImage && <SlideImage src = {slide.extraImage}/> }
                        </SlideContainer>
                    </SlideBlur>
                </SlideHolder>
            ) }
            <SlidesButton position="prev" onClick={() => setIndex(index === 0 ? props.slides.length - 1 : index - 1)}/>
            <SlidesButton position="next" onClick={() => setIndex(index === props.slides.length - 1 ? 0 : index + 1)} />
            <SlideDotContainer align="center">
            { props.slides.map((slide,slideIndex) =>
                <SlideDot key={"slideDot"+slideIndex} active={slideIndex === index} onClick={() => setIndex( slideIndex )}/>
            ) }
                                
            </SlideDotContainer>
        </SlidesHolder>
    )
}

export default Slides

Slides.defaultProps = {
    slides: []
}

Slides.propTypes = {
    slides: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        body: PropTypes.string,
        extraImage: PropTypes.string,
        color: PropTypes.string,
        image: PropTypes.string
    })), 
    autoMove: PropTypes.bool,
    timeSlide: PropTypes.number,
    height: PropTypes.string   
}