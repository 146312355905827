import React from 'react';
import { 
    NewsContainer, 
    NewsTitle,
    NewsSubTitle,
    NewsBody,
    NewsImg
} from './style';
import { Row } from '..';

const NewsItem = ({title, date, body, image, url}) => {
    const openLink = () => {
        window.open(url,"_blank")
    }
    return (
        <NewsContainer onClick={openLink}>
            <Row padding={"0"}>
                <NewsTitle>
                    {title}
                </NewsTitle>
                <NewsSubTitle>
                    {date}
                </NewsSubTitle>
            </Row>
            <NewsBody>
                {body}
            </NewsBody>
            <NewsImg src={image}/>
        </NewsContainer>        
    )
}

export default NewsItem