import styled from "styled-components";

export const STextArea = styled.textarea`
    padding: .8rem;
    background-color: #fff;
    &::placeholder{
        color: #deebe4;
    }
     &:focus{
        color: #19593b;
    }
    border-radius: .2rem;
    box-shadow: none;
    border: none;
    flex: 1;
    margin: 0 1rem;
    width: 100%;
    resize: none;    
`;