import styled from "styled-components";
import { breakpoints } from "../../breakpoints";

export const MainHolder = styled.div`
    display: flex;
    flex-basis: row;
    position: absolute;
    top: 4.7rem;
    left: 0;
    bottom: 0;    
    width: 100%;
    overflow: hidden;
`;


export const SideBarHolder = styled.div`
    display: flex;
    flex-basis: column;
    flex: 1;
    background-color: #2B905D;
    color: white;    
    @media (min-width: 0px) {
        flex: 0;       
        padding: 0;
    }    
    @media ${breakpoints.laptop} {
        flex: 1;       
    }    
`;

export const ContentHolder = styled.div`    
    flex: 10;
    padding: ${ ({ padding }) => padding ? padding : "1rem" };
    background-color: white;
    overflow-x: hidden;
    overflow-y: auto;
`;

export const NavHolder = styled.div`    
    display: flex;
    flex-direction: row;
    height: 2.5rem;
    background-color: ${ ({ theme }) => theme.colors.ligthSecondary };
    border-top: 4px solid ${ ({ theme }) => theme.colors.primary };
    padding: 1rem;
    @media (min-width: 0px) {
        .responsive{
            display: none;
        }        
    }    
    @media ${breakpoints.laptop} {
        .responsive{
            display: flex;
        }        
    }
`;


export const NavLogo = styled.img`    
    flex: 1;  
`;

export const NavRigthHolder = styled.div`    
    flex: 5;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;  
`;

export const NavImgItem = styled.img`    
    height: ${ ({ height }) => height ? height : '50%'};  
    margin: 0 1rem ;
    cursor: pointer;
`;

export const UserNameHolder = styled.p`    
    font-family: Eras;
    color: #4f4f4f;
    font-size: 1.3rem; 
    margin: 0 1rem ;    
`;

export const UserPicHolder = styled.img`    
    height: 3rem;
    width: 3rem;
    border: 1px solid ${ ({ theme }) => theme.colors.primary };
    border-radius: 3rem;
    padding: .2rem;
    object-fit: cover;
`;