import styled from "styled-components";
import { breakpoints } from "../../breakpoints";

export const PersonContainer = styled.div`
    margin: .3rem .3rem;
    padding: .8rem;
    border-radius: .5rem;
    border: 1px solid ${ ({ theme, border }) =>  border ? border : theme.colors.primary  };
    display: flex;
    flex-direction: row;
    width: 28%;
    @media (min-width: 0px) {              
        width: 80%;
    }            
    @media ${breakpoints.tablet} {        
        width: 45%;        
    } 
    @media ${breakpoints.laptop} {        
        width: 30.1%;        
    } 
`;

export const PeopleContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

export const LoaderContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const InfoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 2;
    flex-direction: column;
    padding: .5rem;
    font-size: .8rem;
    color: ${ ({ theme }) => theme.colors.secondary };
`;

export const InfoTitle = styled.span`
    font-size: 1.2rem;
    color: ${ ({ theme }) => theme.colors.primary };
    font-weight: bold;
`;

export const InfoValue = styled.span`
    font-size: 1rem;
    color: ${ ({ theme }) => theme.colors.strongSecondary };
    font-style: italic;
`;

export const PersonImg = styled.img`
    border-radius: 10rem;
    max-width: 5rem;
    max-height: 5rem;
    flex: 1;    
    padding: .1rem;
    border: 1px solid ${ ({ theme }) => theme.colors.ligthSecondary };
`;