import React from 'react';
import { SButton } from './style';

const Button = (props) => {
    return(
        <SButton type="button" {...props}>
            {props.text}
        </SButton>
    )
}

export default Button