import styled from "styled-components";

export const SButton = styled.button`
    background-color: #185237;
    padding: .8rem 2rem;
    font-size: 1.2rem;
    color: #fff;
    border: 0;
    color: white;
    border-radius: .5rem;
    font-family: Eras;
    cursor: pointer;
    width: ${({ width })=> width ? width : '80%'};
`;