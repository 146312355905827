import React from 'react';
import { 
    SideBarUserImageHolder,
    SideBarUserImage,
    SideBarItemText,
    SideBarRow,
    SideBarIcon,
    SideBarHolder,
    SideBarButton,
    SideBarSection
} from './style';
import { Link } from 'gatsby';
import { useSelector} from 'react-redux';

const SIDEBAR_ITEMS = [
{
    icon: "/images/icons/ico_home.svg",
    text: "Inicio",
    link: "/profile"
}/*,{
    icon: "/images/icons/ico_equipo.svg",
    text: "Equipo",
    link: "/team"
},{
    icon: "/images/icons/ico_calendario.svg",
    text: "Calendario",
    link: "/calendar"
},{
    icon: "/images/icons/ico_personas.svg",
    text: "Personas",
    link: "/people"
},{
    icon: "/images/icons/ico_archivos.svg",
    text: "Archivos",
    link: "/files"
}*/]

const SideBar = () => {
    const { user } = useSelector(state=>state) 
    return(
        <SideBarHolder>
            <SideBarSection justifyContent={"flex-start"}>
                <SideBarUserImageHolder>
                    <SideBarUserImage src={ user.image ? user.image :  "/images/icons/ico_user_login.svg"} />
                </SideBarUserImageHolder>
                { SIDEBAR_ITEMS.map( (item,key) =>
                    <Link key={key} to={item.link} activeClassName='activeLink'>
                        <SideBarRow>
                            <SideBarIcon src={item.icon} />                            
                            <SideBarItemText className="responsive">
                                {item.text}
                            </SideBarItemText>
                            <SideBarIcon  className="responsive" src={"/images/icons/ico_flecha_menu.svg"} />                            
                        </SideBarRow>
                    </Link>)}
                </SideBarSection>
                <SideBarSection justifyContent={"center"} flex={2}>
                {/*    <SideBarButton>
                        Recibos de <b>Nómina</b>
                    </SideBarButton>
                    <SideBarButton>
                        Solicitar <b>Vacaciones</b>
                    </SideBarButton>*/}
                </SideBarSection>
                <SideBarSection justifyContent={"flex-end"}>
                    <Link to={"/"} style={{width: "100%"}}>
                        <SideBarRow>
                            <SideBarIcon src={"/images/icons/ico_logout.svg"} height={"80%"} />
                            <SideBarItemText className="responsive">
                                Cerrar Sesión
                            </SideBarItemText>                        
                        </SideBarRow>
                    </Link>
                </SideBarSection>
        </SideBarHolder>
    )
}

export default SideBar;