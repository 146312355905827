import styled from "styled-components";
import { breakpoints } from "../../breakpoints";

export const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: ${ ({ padding }) => padding ? padding : "1.5rem" };
    width: ${ ({ width }) => width ? width : '100%'  };
    justify-content: center;
    align-items: center;
    @media (min-width: 0px) {              
        flex-direction: ${ ({ responsive }) => responsive ? "column" : "row" };
    }        
    @media ${breakpoints.tablet} {
        flex-direction: row;             
    } 
`;