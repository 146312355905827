import React from 'react'
import {
    useSpring, 
    animated
} from 'react-spring'
import { theme } from '../../theme'

const StarLoader = props => {        
    

    const {o, color} = useSpring({
        from: {
            o: 1, 
            color: 'red',          
        },
        o: 0,      
        color: 'green',
        config:{
            duration:1500
        }        
    })

    const { scale } = useSpring({
        from: {
            scale: .5,                           
        },
        to: async (next, cancel) => {
            await next({scale: 15 })        
            await next({scale: 20})
          },            
        config:{
            duration:1000
        }
      })
    
    return (
        <animated.div style={{           
            color,
            background: o.interpolate(o => `rgba(255, 255, 255, ${o})`),
            position: "absolute",
            top:0,
            bottom:0,
            right:0,
            left:0,
            display: o.interpolate({range:[0,1], output:[0,1]}).interpolate(o => `${o ? "flex" : "none"}` ),
            justifyContent: "center",
            alignItems: "center",
            zIndex: 99999,     
            overflow: "hidden"       
        }}>            
            <animated.svg style={{
                transform: scale.interpolate(scale => `scale(${scale})`),                
            }} xmlns="http://www.w3.org/2000/svg" width="50%" height="50%" fill={theme.colors.primary} viewBox="0 0 25 25">
              <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.326 18.266l-4.326-2.314-4.326 2.313.863-4.829-3.537-3.399 4.86-.671 2.14-4.415 2.14 4.415 4.86.671-3.537 3.4.863 4.829z"/>
            </animated.svg>
        </animated.div>
    )
}

export default StarLoader