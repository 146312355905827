import React from 'react'
import {
    SInput
} from './style'

const Input = props => {
    return(
        <SInput {...props} />
    )
}

export default Input