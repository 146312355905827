import styled from "styled-components";
import { breakpoints } from "../../breakpoints";

export const ToolbarHolder = styled.div`
    background: ${({ backgroundColor,theme }) => backgroundColor ? backgroundColor : theme.colors.secondary };
    color:  ${({ textColor,theme }) => textColor ? textColor : theme.colors.white };
    padding: 0 1rem;
    display: flex;
    flex-direction: row;    
`;

export const TextHolder = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0.5rem 1rem;    
    cursor: ${ ({ cursor }) => cursor ? cursor : 'default' };
    @media (min-width: 0px) {
        &.responsive{
            display: none;
        }
        &.responsiveMobile{
            display: none;
        }
    }
    @media ${breakpoints.tablet} {
        &.responsiveMobile{
            display: flex;
        }
    }
    @media ${breakpoints.laptop} {
        &.responsive{
            display: flex;
        }
    }
`;

export const ToolbarText = styled.p`
    flex: 3;    
    padding: 0;
    margin: 0;
    font-size: 0.8rem;
    line-height: 1rem;
    vertical-align: middle;
    white-space: nowrap;
`;

export const ToolbarIcon = styled.img`
    padding:  ${ ({ padding }) => padding ? padding : '0 1rem' };
    height: 100%;
    margin: ${ ({ margin }) => margin ? margin : 'default' };
    cursor: ${ ({ cursor }) => cursor ? cursor : 'default' };
    flex: ${ ({ flex }) => flex ? flex : 'default' };
`;


export const ToolbarLeft = styled.div`
    display: flex; 
    flex: 1;

`;

export const ToolbarRigth = styled.div`
    display: flex;
    flex: 1;
    align-items: flex-end;
    justify-content: flex-end;
`;