import React, { useState, useEffect } from 'react';
import { 
    PersonContainer ,
    LoaderContainer,
    PeopleContainer,
    PersonImg,
    InfoContainer,
    InfoValue,
    InfoTitle
} from './style';
import Loader from "react-loader-spinner"
import api from '../../services/api'
import { useSelector} from 'react-redux';
import Row from '../Row';
export default () => {
    
    const [people, setPeople] = useState([])
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)       
    const { user } = useSelector(state=>state) 
    useEffect(()=>{        
        api.get('/users',{
            headers: { "Authorization": "Bearer " + user.access_token }
        }).then(res=>{                  
            if (res.status === 200) {
                if (res.data.errors) {
                    console.log('algo fallo al cargar lista de usuarios',res.data)
                    setError("Ocurrio un problema cargando la información. Error: " + res.data.errors.message )
                } else {
                    setPeople(res.data)
                }
            }
            setLoading(false)
        }).catch(err=>{
            console.log('algo fallo al cargar lista de usuarios',err)
            setError("Ocurrio un problema cargando la información. " + err.toString() )
            setLoading(false)            
        })
    })
    if (loading) {
        return(
            <LoaderContainer>
                <Loader type="Grid" height={100} width={100}/>
            </LoaderContainer>
        )
    }
    if (error !== null) {
        return(<span>{error}</span>)
    }

                    
    return(
        <React.Fragment>
            <PeopleContainer>
                {
                    people.map(({ name, email, phone, position,image, surname },index)=>
                        <PersonContainer  key={index}>
                            <PersonImg src={image?image:'/images/icons/ico_user_login.svg'} />
                            <InfoContainer>                            
                                <Row padding={"0"}>
                                    <InfoTitle>
                                        {name} {surname}
                                    </InfoTitle>
                                </Row>
                                <Row padding={"0"}>
                                    <InfoValue>
                                        {position}
                                    </InfoValue>
                                </Row>                            
                                {email} {phone}                            
                            </InfoContainer>
                        </PersonContainer>
                    )
                }
            </PeopleContainer>
        </React.Fragment>
    )
}

