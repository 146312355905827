import styled from "styled-components";
import { breakpoints } from "../../breakpoints";

export const SlidesHolder = styled.div`
  position: relative;
  width: 100%;
  height: ${({ height }) => (height ? height : "44rem")};

`;

export const SlideHolder = styled.div`
  background-image: url(${({ image }) => image ? image : "https://image.shutterstock.com/image-vector/man-vs-woman-runners-vector-260nw-639227380.jpg"});
  opacity: 0;
  background-size: cover;
  background-repeat: no-repeat;
  height: ${({ height }) => (height ? height : "44rem")};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center;
  z-index: 0;
  &.activeSlide {    
    opacity: 1;  
    z-index:1;
  } 
  transition: opacity 1s ease-out;
`;

export const SlideContainer = styled.div`
  height: ${({ height }) => (height ? height : "44rem")};
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 1;
`;

export const SlideTitle = styled.h1`
  position: relative;
  opacity: 1;
  text-align: center;
  color: ${({ color }) => (color ? color : "#fff")};
  z-index: 10;
  margin: 0;
  font-size: 4rem;
  font-weight: 400;
  @media (min-width: 0px) {
    font-size: 2rem;
    padding: 0 5rem;
  }  
  @media ${breakpoints.laptop} {
    font-size: 4rem;
    padding: 1rem 12rem;
  }
`;

export const SlideBody = styled.p`
  position: relative;
  opacity: 1;
  text-align: center;
  color: ${({ color }) => (color ? color : "#fff")};
  font-size: 1.4rem;
  z-index: 10;
  margin: 0;
  line-height: 1;
  @media (min-width: 0px) {
    font-size: 1rem;
    padding: 1rem 5rem;
  }
  @media ${breakpoints.laptop} {
    font-size: 1.4rem;
    padding: 1rem 12rem;
  }
`;

export const SlideBlur = styled.div`
  background-color: ${({ color }) => (color ? color : "rgba(63,123,112,0.5)")};
  height: ${({ height }) => (height ? height : "44rem")};
`;

export const SlidesButton = styled.button`
  align-self: stretch;
  background: none;
  border: 0;
  color: rgba(0, 0, 0, 1);
  cursor: pointer;
  flex: 0 0 auto;
  font-size: 24px;
  opacity: 1;
  outline: none;
  padding: 16px;
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  z-index: 1000;
  position: absolute;
  border-style: solid;
  border-width: 0.25em 0.25em 0 0;
  content: "";
  display: inline-block;
  width: 0.45em;
  height: 0.45em;
  border-color: ${({ arrowColor, theme }) =>
    arrowColor ? arrowColor : "#fff"};
  left: ${({ position }) => (position === "prev" ? "8%" : "")};
  right: ${({ position }) => (position === "next" ? "8%" : "")};
  top: calc(50% - 1.6rem);
  transform: ${({ position }) =>
    position === "next" ? "rotate(45deg)" : "rotate(-135deg)"};
`;

export const SlideDotContainer = styled.div`
  position: absolute;
  bottom: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const SlideDot = styled.div`
  width: 1.5em;
  height: 1.5em;
  border-radius: 1em;
  border: 1px solid transparent;
  margin: 1em;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.primary : "white"};
  &:hover {
    background-color: ${({ theme }) => theme.colors.mediumPrimary};
  }
  z-index: 2;
`;

export const SlideImage = styled.img`
  max-width: 80%;
  max-height: 60%;
  height: 8rem;
  z-index: 1;
`;
